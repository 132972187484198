import React, {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {makeStyles, Popover} from '@material-ui/core';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Scrollbar from '../Scrollbar';
import IntlMessages from '../../utility/IntlMessages';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import NotificationItem from './NotificationItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import {useHistory} from 'react-router-dom';
import {
  onGetUnreadNotificationList,
  onReadNotification,
} from '../../../redux/actions/Notification';
import {useDispatch, useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('xl')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    // width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: 'auto',
      // borderLeft: 'solid 1px',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
  notiIcon: {
    // fontSize: 22,
    fontSize: 30,
    color: theme.palette.text.secondary,
    // [theme.breakpoints.up('xl')]: {
    //   fontSize: 30,
    // },
  },
  notificationItem: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const Notifications = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorNotification, setAnchorNotification] = React.useState(null);
  const {isLoading, getAccessTokenSilently} = useAuth0();
  const {notificationUnreadList, notificationUnreadCount} = useSelector(
    ({notification}) => notification,
  );
  const [popoverDescription, setPopoverDescription] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const containerRef = React.useRef();

  useEffect(() => {
    if (!isLoading) {
      try {
        getAccessTokenSilently()
          .then(() => {
            dispatch(onGetUnreadNotificationList(false));
          })
          .catch((err) => {});
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }, [dispatch, isLoading, getAccessTokenSilently]);

  const onClickNotificationButton = (event) => {
    setAnchorNotification(event.currentTarget);
  };

  return (
    <>
      <IconButton
        className={clsx(classes.notiBtn, 'notiBtn')}
        aria-label='show new notifications'
        color='inherit'
        onClick={onClickNotificationButton}>
        <Badge
          overlap='rectangular'
          className={classes.badge}
          badgeContent={notificationUnreadCount}
          color='secondary'>
          <NotificationsActiveIcon
            className={clsx(classes.notiIcon, 'notiIcon')}
          />
        </Badge>
        <Hidden mdUp>
          <Box
            style={{marginLeft: 20}}
            fontSize={16}
            fontFamily='Poppins'
            fontWeight={Fonts.REGULAR}
            component='span'>
            <IntlMessages id='common.notifications' />
          </Box>
        </Hidden>
      </IconButton>

      {popoverDescription ? (
        <Popover
          anchorEl={anchorNotification}
          id='language-switcher'
          className={classes.crPopover}
          keepMounted
          open={Boolean(anchorNotification)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => setAnchorNotification(null)}>
          <Box>
            <Box px={5} py={3} display='flex'>
              <Box
                mr={3}
                onClick={() => {
                  setPopoverDescription(false);
                }}>
                <NavigateBeforeIcon className={classes.pointer} />
              </Box>
              <Box
                component='h5'
                fontFamily='Poppins'
                fontSize={16}
                style={{marginTop: 1}}>
                {moment(selectedItem.created).format('MMM Do YYYY, h:mm a')}
              </Box>
            </Box>
            <Scrollbar className='scroll-submenu'>
              <Box ml={5} mr={5} mt={2} mb={5}>
                <Box
                  mr={2}
                  component='span'
                  display='inline-block'
                  color='text.primary'
                  fontWeight={Fonts.MEDIUM}>
                  {selectedItem.name}
                </Box>
                {selectedItem.description && (
                  <Box component='p'>{selectedItem.description}</Box>
                )}
                {selectedItem.url && (
                  <Box mt={5}>
                    <Button
                      onClick={() => {
                        setAnchorNotification(null);
                        setPopoverDescription(false);
                        setSelectedItem(null);
                        dispatch(
                          onReadNotification(selectedItem.id, {read: true}),
                        );
                        if (selectedItem.url) {
                          history.push(selectedItem.url);
                        }
                      }}
                      variant='contained'
                      size='small'
                      color='primary'>
                      More
                    </Button>
                  </Box>
                )}
              </Box>
            </Scrollbar>
          </Box>
        </Popover>
      ) : (
        <Box ref={containerRef}>
          <Popover
            anchorEl={anchorNotification}
            id='language-switcher'
            className={classes.crPopover}
            keepMounted
            open={Boolean(anchorNotification)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={() => setAnchorNotification(null)}>
            <Box>
              <Box px={5} py={3}>
                <Box component='h5' fontFamily='Poppins' fontSize={16}>
                  <IntlMessages id='common.notifications' />(
                  {notificationUnreadCount})
                </Box>
              </Box>

              {/*<Scrollbar className='scroll-submenu'>*/}
              <List
                className={classes.list}
                onClick={() => {
                  setAnchorNotification(null);
                }}>
                {notificationUnreadList &&
                  notificationUnreadList.map((item) => (
                    <NotificationItem
                      listStyle={classes.notificationItem}
                      key={item.id}
                      item={item}
                      setPopoverDescription={setPopoverDescription}
                      setSelectedItem={setSelectedItem}
                    />
                  ))}
              </List>
              {/*</Scrollbar>*/}

              <Box mt={2}>
                <Button
                  onClick={() => {
                    setAnchorNotification(null);
                    history.push('/alerts/');
                  }}
                  className={classes.btnPopover}
                  variant='contained'
                  color='primary'>
                  <IntlMessages id='common.viewAll' />
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      )}
    </>
  );
};

export default Notifications;
