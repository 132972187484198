import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Loader} from '../../@crema';
import {useDispatch, useSelector} from 'react-redux';
import {onFetchMainSettings} from '../../redux/actions';
import _ from 'lodash/core';

const Settings = ({children}) => {
  const dispatch = useDispatch();
  const {settings} = useSelector(({settings}) => settings);

  useEffect(() => {
    dispatch(onFetchMainSettings());
  }, [dispatch]);

  return _.isEmpty(settings) ? <Loader /> : <>{children}</>;
};

export default Settings;

Settings.propTypes = {
  children: PropTypes.node.isRequired,
};
