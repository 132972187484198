import camelcaseKeys from 'camelcase-keys';

import {
  CREATE_NEW_ENTITY_DEVICE,
  DELETE_ENTITY_DEVICE,
  GET_ENTITY_DEVICE_LIST,
  GET_ENTITY_DEVICE_LIST_ALL,
  ON_REMOVE_ENTITY_TAG,
  UPDATE_ENTITY_DEVICE,
} from '../../shared/constants/ActionTypes';

const initialState = {
  entityDeviceListAll: null,
  entityDeviceList: null,
  entityDeviceCount: 0,
};

const entityDeviceReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_ENTITY_DEVICE_LIST_ALL:
      return {
        ...state,
        entityDeviceListAll: payload,
      };

    case GET_ENTITY_DEVICE_LIST:
      return {
        ...state,
        entityDeviceList: payload.results,
        entityDeviceCount: payload.count,
      };

    case CREATE_NEW_ENTITY_DEVICE:
      return {
        ...state,
        entityDeviceList: [action.payload, ...state.entityDeviceList],
        entityDeviceCount: state.entityDeviceCount + 1,
      };

    case DELETE_ENTITY_DEVICE: {
      return {
        ...state,
        entityDeviceList: state.entityDeviceList
          ? state.entityDeviceList.filter((item) => item.id !== action.payload)
          : null,
        entityDeviceCount: state.entityDeviceCount - 1,
      };
    }

    case UPDATE_ENTITY_DEVICE:
      return {
        ...state,
        entityDeviceList: state.entityDeviceList.map((entity) =>
          entity.id === payload.id ? payload : entity,
        ),
      };

    case ON_REMOVE_ENTITY_TAG:
      return {
        ...state,
        entityDeviceList:
          state.entityDeviceList &&
          state.entityDeviceList.map((entity) =>
            entity.id === payload.entityGroupId
              ? {
                  ...entity,
                  tags:
                    entity.tags &&
                    entity.tags.filter((tag) => tag.id !== payload.id),
                }
              : entity,
          ),
      };

    default:
      return state;
  }
};
export default entityDeviceReducer;
