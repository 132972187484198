const routesConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.app.dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard/',
  },
  {
    id: 'events',
    title: 'Event Log',
    messageId: 'sidebar.log',
    type: 'item',
    icon: 'fact_check',
    url: '/events/',
  },
  {
    id: 'drivers',
    title: 'Drivers',
    messageId: 'sidebar.drivers',
    type: 'item',
    icon: 'people',
    url: '/entities/drivers/',
  },
  {
    id: 'vehicles',
    title: 'Vehicles',
    messageId: 'sidebar.vehicles',
    type: 'item',
    icon: 'local_shipping',
    url: '/entities/vehicles/',
  },
  {
    id: 'devices',
    title: 'Devices',
    messageId: 'sidebar.devices',
    type: 'item',
    icon: 'phone_iphone',
    url: '/entities/devices/',
  },
  {
    id: 'alerts',
    title: 'Alerts',
    messageId: 'sidebar.alerts',
    type: 'item',
    icon: 'notifications',
    url: '/alerts/',
    notificationCount: true,
  },
];
export default routesConfig;
