import camelcaseKeys from 'camelcase-keys';

import {
  GET_UNIFIED_TRIP_LIST,
  CLEAR_UNIFIED_TRIP_LIST,
} from '../../shared/constants/ActionTypes';

const initialState = {
  unifiedTripList: null,
};

const unifiedTripReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_UNIFIED_TRIP_LIST:
      return {
        ...state,
        unifiedTripList: payload,
      };

    case CLEAR_UNIFIED_TRIP_LIST:
      return {
        ...state,
        unifiedTripList: null,
      };

    default:
      return state;
  }
};
export default unifiedTripReducer;
