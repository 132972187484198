export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const defaultUser = {
  role: 'user',
  photoURL: '/assets/images/placeholder.jpg',
};

export const initialUrl = '/dashboard/'; // this url will open after login
