import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {errorPagesConfigs} from './errorPages';
import {notificationConfig} from './Notification/config';
import {initialUrl} from '../shared/constants/AppConst';
import {entityConfig} from './Entity/config';
import {dashboardConfig} from './Dashboard/config';
import {appsConfig} from './apps';

const routeConfigs = [
  ...errorPagesConfigs,
  ...notificationConfig,
  ...entityConfig,
  ...appsConfig,
  ...dashboardConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
