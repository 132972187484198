import camelcaseKeys from 'camelcase-keys';

import {
  CLEAR_WIDGET_LIST,
  CREATE_NEW_WIDGET,
  DELETE_WIDGET,
  GET_WIDGET_LIST,
  UPDATE_WIDGET,
} from '../../shared/constants/ActionTypes';

const initialState = {
  widgetList: null,
};

const widgetReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_WIDGET_LIST:
      return {
        ...state,
        widgetList: payload,
      };

    case CREATE_NEW_WIDGET:
      return {
        ...state,
        widgetList: state.widgetList
          ? [...state.widgetList, payload]
          : [payload],
      };

    case DELETE_WIDGET: {
      return {
        ...state,
        widgetList: state.widgetList
          ? state.widgetList.filter((item) => item.id !== payload)
          : null,
      };
    }

    case UPDATE_WIDGET:
      return {
        ...state,
        widgetList: state.widgetList.map((widget) =>
          widget.id === payload.id ? payload : widget,
        ),
      };

    case CLEAR_WIDGET_LIST:
      return {
        ...state,
        widgetList: null,
      };

    default:
      return state;
  }
};

export default widgetReducer;
