import camelcaseKeys from 'camelcase-keys';

import {GET_UNIFIED_DEVICE_LIST} from '../../shared/constants/ActionTypes';

const initialState = {
  unifiedDeviceList: null,
};

const unifiedDeviceReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_UNIFIED_DEVICE_LIST:
      return {
        ...state,
        unifiedDeviceList: payload,
      };

    default:
      return state;
  }
};
export default unifiedDeviceReducer;
