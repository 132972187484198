import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
import {Redirect} from 'react-router-dom';

export const entityConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: ['/entities/:tab/'],
        component: React.lazy(() => import('./index')),
      },
      {
        path: '/entities/',
        component: () => <Redirect to='/entities/drivers/' />,
      },
    ],
  },
];

export const entityTypeDict = {
  DRIVER: {
    id: 'DRIVER',
    name: 'Driver',
  },
  DEVICE: {
    id: 'DEVICE',
    name: 'Device',
  },
  VEHICLE: {
    id: 'VEHICLE',
    name: 'Vehicle',
  },
};
