import {
  SET_INITIAL_PATH,
  TOGGLE_NAV_COLLAPSED,
  FETCH_SETTINGS,
} from '../../shared/constants/ActionTypes';
import camelcaseKeys from 'camelcase-keys';

const initialSettings = {
  navCollapsed: false,
  initialPath: '/',
};

const settingsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };

    case TOGGLE_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };

    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.payload,
      };

    case FETCH_SETTINGS:
      const {sourceTypeDict} = camelcaseKeys(action.payload);
      const sourceTypes = {};
      Object.keys(sourceTypeDict).forEach((item) => {
        sourceTypes[item] = camelcaseKeys(sourceTypeDict[item], {deep: true});
      });

      return {
        ...state,
        settings: {
          ...camelcaseKeys(action.payload, {deep: true}),
          sourceTypeDict: sourceTypes,
        },
      };

    default:
      return state;
  }
};

export default settingsReducer;
