import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// export default axios.create({
//   headers: {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   },
// });

export default axios;
