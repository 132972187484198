import qs from 'qs';
import {
  ADD_DATA_LOG,
  DELETE_DATA_LOGS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_WARNING,
  GET_DATA_LOG_LIST,
  GET_DATA_SOURCE_CATEGORIES,
  SELECT_DATA_LOG_LOCATION,
  UPDATE_DATA_LOG_COUNT,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/Utils';
import snakeCaseKeys from 'snakecase-keys';
import moment from 'moment';

export const onGetDataLogList = (
  category,
  dataSourceKeys,
  datetimeStart,
  datetimeEnd,
  starred,
  drivers,
  devices,
  vehicles,
  entityTags,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    let params = {
      dataSourceKey: dataSourceKeys,
      category,
      starred,
      driver: drivers ? drivers.toString() : null,
      device: devices ? devices.toString() : null,
      vehicle: vehicles ? vehicles.toString() : null,
      entityTag: entityTags ? entityTags.toString() : null,
    };

    if (datetimeStart) {
      params.datetimeStart = datetimeStart;
    } else {
      params.datetimeStart = moment().subtract(1, 'days').format();
    }
    if (datetimeEnd) {
      params.datetimeEnd = datetimeEnd;
    } else {
      params.datetimeEnd = moment().format();
    }

    try {
      params = snakeCaseKeys(params);
      const res = await Api.get('/api/unified_data/data_points/', {
        params: {
          ...params,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, {indices: false});
        },
      });
      if (res.status === 200) {
        dispatch({type: GET_DATA_LOG_LIST, payload: res.data});
        if (res.data && res.data.length >= 500) {
          dispatch({
            type: FETCH_WARNING,
            payload:
              'The search result is limited to 500 records. ' +
              'Adjust the filter to see all the results.',
          });
        } else {
          dispatch({type: FETCH_SUCCESS});
        }
        return {err: [], data: res.data};
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
        return {err: [messages['message.somethingWentWrong']], data: null};
      }
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      return {err: [messages['message.somethingWentWrong']], data: null};
    }
  };
};

export const selectDataLogLocation = (dataLog) => {
  return async (dispatch) => {
    dispatch({type: SELECT_DATA_LOG_LOCATION, payload: dataLog});
  };
};

export const updateDataLogCount = (cnt) => {
  return async (dispatch) => {
    dispatch({type: UPDATE_DATA_LOG_COUNT, payload: cnt});
  };
};

export const addDataLogLocal = (dataLog) => {
  return async (dispatch) => {
    dispatch({type: ADD_DATA_LOG, payload: dataLog});
  };
};

export const deleteDataLogsLocal = (dataSourceKey) => {
  return async (dispatch) => {
    dispatch({type: DELETE_DATA_LOGS, payload: dataSourceKey});
  };
};

export const onGetSourceCategories = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get(`/api/unified_data/data_points/source_categories/`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_DATA_SOURCE_CATEGORIES, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
