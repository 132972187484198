import camelcaseKeys from 'camelcase-keys';

import {
  ADD_NOTIFICATION,
  DELETE_READ_NOTIFICATION,
  GET_NOTIFICATION_LIST,
  GET_UNREAD_NOTIFICATION_LIST,
  NOTIFICATION_READ_ALL,
  ON_NOTIFICATION_PAGE_CHANGE,
  READ_NOTIFICATION,
} from '../../shared/constants/ActionTypes';

const initialState = {
  notificationList: null,
  notificationCount: 0,
  notificationUnreadList: null,
  notificationUnreadCount: 0,
  page: 0,
};

const notificationReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: payload.results,
        notificationCount: payload.count,
      };

    case GET_UNREAD_NOTIFICATION_LIST:
      return {
        ...state,
        notificationUnreadList: payload.results,
        notificationUnreadCount: payload.count,
      };

    case ON_NOTIFICATION_PAGE_CHANGE: {
      return {
        ...state,
        page: payload,
      };
    }

    case READ_NOTIFICATION: {
      return {
        ...state,
        notificationList: state.notificationList
          ? state.notificationList.map((notification) =>
              notification.id === payload.id ? payload : notification,
            )
          : null,
        notificationUnreadList: state.notificationUnreadList
          ? state.notificationUnreadList.map((notification) =>
              notification.id === payload.id ? payload : notification,
            )
          : null,
        notificationCount:
          state.notificationCount > 0
            ? state.notificationCount - (payload.read ? 1 : -1)
            : 0,
        notificationUnreadCount:
          state.notificationUnreadCount > 0
            ? state.notificationUnreadCount - (payload.read ? 1 : -1)
            : 0,
      };
    }

    case ADD_NOTIFICATION: {
      return {
        ...state,
        notificationList: state.notificationList
          ? [payload, ...state.notificationList]
          : null,
        notificationCount: state.notificationCount + 1,
        notificationUnreadList: state.notificationUnreadList
          ? [payload, ...state.notificationUnreadList]
          : null,
        notificationUnreadCount: state.notificationUnreadCount + 1,
      };
    }

    case DELETE_READ_NOTIFICATION: {
      return {
        ...state,
        notificationUnreadList: state.notificationUnreadList
          ? state.notificationUnreadList.filter(
              (notification) => notification.id !== payload.id,
            )
          : null,
      };
    }

    case NOTIFICATION_READ_ALL: {
      return {
        ...state,
        notificationList: state.notificationList
          ? state.notificationList.map((notification) => ({
              ...notification,
              read: true,
            }))
          : null,
        notificationUnreadList: state.notificationUnreadList
          ? state.notificationUnreadList.map((notification) => ({
              ...notification,
              read: true,
            }))
          : null,
        notificationUnreadCount: 0,
      };
    }

    default:
      return state;
  }
};
export default notificationReducer;
