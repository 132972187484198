//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const FETCH_WARNING = 'fetch_warning';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//NOTIFICATION
export const GET_UNREAD_NOTIFICATION_LIST = 'GET_UNREAD_NOTIFICATION_LIST';
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const ON_NOTIFICATION_PAGE_CHANGE = 'ON_NOTIFICATION_PAGE_CHANGE';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_READ_NOTIFICATION = 'DELETE_READ_NOTIFICATION';
export const NOTIFICATION_READ_ALL = 'NOTIFICATION_READ_ALL';

//DATA_LOG
export const GET_DATA_LOG_LIST = 'GET_DATA_LOG_LIST';
export const SELECT_DATA_LOG_LOCATION = 'SELECT_DATA_LOG_LOCATION';
export const UPDATE_DATA_LOG_COUNT = 'UPDATE_DATA_LOG_COUNT';
export const ADD_DATA_LOG = 'ADD_DATA_LOG';
export const DELETE_DATA_LOGS = 'DELETE_DATA_LOGS';
export const GET_DATA_SOURCE_CATEGORIES = 'GET_DATA_SOURCE_CATEGORIES';

//DATA_LOG_EXTENSION
export const UPDATE_DATA_LOG_EXTENSION = 'UPDATE_DATA_LOG_EXTENSION';

//SETTINGS
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

//ROUTE
export const SAVE_CURRENT_ROUTE = 'SAVE_CURRENT_ROUTE';

//USER_PROFILE
export const GET_USER_PROFILE_LIST = 'GET_USER_PROFILE_LIST';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

//ENTITIES
export const GET_ENTITY_DRIVER_LIST = 'GET_ENTITY_DRIVER_LIST';
export const GET_ENTITY_DRIVER_LIST_ALL = 'GET_ENTITY_DRIVER_LIST_ALL';
export const CREATE_NEW_ENTITY_DRIVER = 'CREATE_NEW_ENTITY_DRIVER';
export const DELETE_ENTITY_DRIVER = 'DELETE_ENTITY_DRIVER';
export const UPDATE_ENTITY_DRIVER = 'UPDATE_ENTITY_DRIVER';

export const GET_ENTITY_DEVICE_LIST = 'GET_ENTITY_DEVICE_LIST';
export const GET_ENTITY_DEVICE_LIST_ALL = 'GET_ENTITY_DEVICE_LIST_ALL';
export const CREATE_NEW_ENTITY_DEVICE = 'CREATE_NEW_ENTITY_DEVICE';
export const DELETE_ENTITY_DEVICE = 'DELETE_ENTITY_DEVICE';
export const UPDATE_ENTITY_DEVICE = 'UPDATE_ENTITY_DEVICE';

export const GET_ENTITY_VEHICLE_LIST = 'GET_ENTITY_VEHICLE_LIST';
export const GET_ENTITY_VEHICLE_LIST_ALL = 'GET_ENTITY_VEHICLE_LIST_ALL';
export const CREATE_NEW_ENTITY_VEHICLE = 'CREATE_NEW_ENTITY_VEHICLE';
export const DELETE_ENTITY_VEHICLE = 'DELETE_ENTITY_VEHICLE';
export const UPDATE_ENTITY_VEHICLE = 'UPDATE_ENTITY_VEHICLE';

export const GET_ENTITY_GROUP_LIST = 'GET_ENTITY_GROUP_LIST';
export const GET_ENTITY_GROUP_LIST_ALL = 'GET_ENTITY_GROUP_LIST_ALL';
export const CREATE_NEW_ENTITY_GROUP = 'CREATE_NEW_ENTITY_GROUP';
export const DELETE_ENTITY_GROUP = 'DELETE_ENTITY_GROUP';
export const UPDATE_ENTITY_GROUP = 'UPDATE_ENTITY_GROUP';

//ENTITY TAGS
export const GET_ENTITY_TAG_LIST = 'GET_ENTITY_TAG_LIST';
export const ON_CREATE_ENTITY_TAG = 'ON_CREATE_ENTITY_TAG';
export const ON_EDIT_ENTITY_TAG = 'ON_EDIT_ENTITY_TAG';
export const ON_DELETE_ENTITY_TAG = 'ON_DELETE_ENTITY_TAG';
export const ON_REMOVE_ENTITY_TAG = 'ON_REMOVE_ENTITY_TAG';

//UNIFIED_DRIVER
export const GET_UNIFIED_DRIVER_LIST = 'GET_UNIFIED_DRIVER_LIST';

//UNIFIED_DEVICE
export const GET_UNIFIED_DEVICE_LIST = 'GET_UNIFIED_DEVICE_LIST';

//UNIFIED_VEHICLE
export const GET_UNIFIED_VEHICLE_LIST = 'GET_UNIFIED_VEHICLE_LIST';

//UNIFIED_TRIPS
export const GET_UNIFIED_TRIP_LIST = 'GET_UNIFIED_TRIP_LIST';
export const CLEAR_UNIFIED_TRIP_LIST = 'CLEAR_UNIFIED_TRIP_LIST';

//CHART_DATASET
export const GET_CHART_DATASET = 'GET_CHART_DATASET';

// DASHBOARD
export const GET_DASHBOARD_LIST = 'GET_DASHBOARD_LIST';
export const CREATE_NEW_DASHBOARD = 'CREATE_NEW_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';

// WIDGET
export const GET_WIDGET_LIST = 'GET_WIDGET_LIST';
export const CREATE_NEW_WIDGET = 'CREATE_NEW_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const CLEAR_WIDGET_LIST = 'CLEAR_WIDGET_LIST';

// PREDEFINED_WIDGET
export const GET_PREDEFINED_WIDGET_LIST = 'GET_PREDEFINED_WIDGET_LIST';
