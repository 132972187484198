import camelcaseKeys from 'camelcase-keys';

import {GET_UNIFIED_DRIVER_LIST} from '../../shared/constants/ActionTypes';

const initialState = {
  unifiedDriverList: null,
};

const unifiedDriverReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_UNIFIED_DRIVER_LIST:
      return {
        ...state,
        unifiedDriverList: payload,
      };

    default:
      return state;
  }
};
export default unifiedDriverReducer;
