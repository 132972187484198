import React, {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {Loader} from '../../@crema';
import {useDispatch} from 'react-redux';
import camelcaseKeys from 'camelcase-keys';
import {onAddNotificationLocal} from '../../redux/actions/Notification';
import {
  addDataLogLocal,
  deleteDataLogsLocal,
} from '../../redux/actions/DataLog';

const WebSocketConnection = ({children}) => {
  const dispatch = useDispatch();
  const {user, isLoading, isAuthenticated} = useAuth0();
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_URL}ws/connect/?sub=${user.sub}`,
      );
      ws.onopen = () => {};

      ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        const payload = camelcaseKeys(response.data.payload, {deep: true});
        if (response.data.type === 'NOTIFICATION') {
          dispatch(onAddNotificationLocal(payload.notification));
        } else if (response.data.type === 'DATA_LOG') {
          dispatch(addDataLogLocal(payload.dataLog));
        } else if (response.data.type === 'DELETE_DATA_LOGS') {
          dispatch(deleteDataLogsLocal(payload.dataSourceKey));
        }
      };

      // https://stackoverflow.com/questions/22431751/websocket-how-to-automatically-reconnect-after-it-dies
      ws.onclose = function (e) {
        // console.log(
        //   'Socket is closed. Reconnect will be attempted in 1 second.',
        //   e.reason,
        // );
        setTimeout(function () {
          // https://stackoverflow.com/questions/53243203/react-hook-useeffect-runs-continuously-forever-infinite-loop
          setCount(Math.random());
        }, 1000);
      };

      ws.onerror = function (err) {
        // console.error(
        //   'Socket encountered error: ',
        //   err.message,
        //   'Closing socket',
        // );
        ws.close();
      };

      return () => {
        if (ws) {
          ws.close();
        }
      };
    }
  }, [dispatch, isLoading, isAuthenticated, user, count]);

  return isLoading || !isAuthenticated ? <Loader /> : <>{children}</>;
};

export default WebSocketConnection;

WebSocketConnection.propTypes = {
  children: PropTypes.node.isRequired,
};
