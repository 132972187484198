import React from 'react';

export const notificationConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/alerts/',
        component: React.lazy(() => import('./index')),
      },
    ],
  },
];
