import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import Notification from './Notification';
import DataLog from './DataLog';
import Route from './Route';
import UserProfile from './UserProfile';
import EntityDriver from './EntityDriver';
import EntityDevice from './EntityDevice';
import EntityVehicle from './EntityVehicle';
import EntityGroup from './EntityGroup';
import EntityTag from './EntityTag';
import UnifiedDriver from './UnifiedDriver';
import UnifiedDevice from './UnifiedDevice';
import UnifiedVehicle from './UnifiedVehicle';
import UnifiedTrip from './UnifiedTrip';
import ChartDataset from './ChartDataset';
import Dashboard from './Dashboard';
import Widget from './Widget';
import PredefinedWidget from './PredefinedWidget';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    notification: Notification,
    dataLog: DataLog,
    route: Route,
    userProfile: UserProfile,
    entityDriver: EntityDriver,
    entityDevice: EntityDevice,
    entityVehicle: EntityVehicle,
    entityGroup: EntityGroup,
    entityTag: EntityTag,
    unifiedDriver: UnifiedDriver,
    unifiedDevice: UnifiedDevice,
    unifiedVehicle: UnifiedVehicle,
    unifiedTrip: UnifiedTrip,
    chartDataset: ChartDataset,
    dashboard: Dashboard,
    widget: Widget,
    predefinedWidget: PredefinedWidget,
  });
export default reducers;
