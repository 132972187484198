import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from '../../shared/constants/AppConst';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBarcode,
  faTemperatureLow,
  faTruckMoving,
  faTachometerAlt,
  faClock,
  faHardHat,
  faPlug,
  faHistory,
  faRoad,
  faShippingFast,
  faInfoCircle,
  faCar,
  faMapMarkedAlt,
  faAddressCard,
  faThumbtack,
  faBatteryFull,
  faMobile,
  faPhone,
  faStar,
  faUnlock,
  faIdCard,
  faCalendarDay,
  faUserClock,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';

import {faToggleOn} from '@fortawesome/fontawesome-free-solid';
import MessageIcon from '@material-ui/icons/Message';
import SatelliteIcon from '@material-ui/icons/Satellite';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';
import EventIcon from '@material-ui/icons/Event';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import RouterIcon from '@material-ui/icons/Router';
import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@mui/icons-material/Videocam';

export const appsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: ['/events/'],
        component: React.lazy(() => import('./DataLog')),
      },
      {
        path: '/events',
        component: () => <Redirect to='/events/' />,
      },
    ],
  },
];

export const dataSourceKeys = {
  AZUGA: {
    id: 'AZUGA',
  },
  NEXTRAQ: {
    id: 'NEXTRAQ',
  },
  INFINIT_I: {
    id: 'INFINIT_I',
  },
  ASSET_LINK_GLOBAL: {
    id: 'ASSET_LINK_GLOBAL',
  },
  SAFE_DRIVE_POD: {
    id: 'SAFE_DRIVE_POD',
  },
  LIFESAVER: {
    id: 'LIFESAVER',
  },
};

export const categories = {
  SPEED: {
    id: 'SPEED',
    name: 'Speeding',
    icon: 'speed',
  },
  FAULT_CODE_SPN: {
    id: 'FAULT_CODE_SPN',
    name: 'Fault Code Spn',
    iconComponent: <FontAwesomeIcon icon={faBarcode} />,
  },
  FAULT_CODE_FMI: {
    id: 'FAULT_CODE_FMI',
    name: 'Fault Codes Fmi',
    iconComponent: <FontAwesomeIcon icon={faBarcode} />,
  },
  BRAKE_SWITCH: {
    id: 'BRAKE_SWITCH',
    name: 'Brake Switch',
    iconComponent: <FontAwesomeIcon icon={faToggleOn} />,
  },
  FUEL_RATE: {
    id: 'FUEL_RATE',
    name: 'Fuel Rate',
    icon: 'localGasStation',
    iconComponent: <LocalGasStationIcon />,
  },
  CLUTCH_SWITCH: {
    id: 'CLUTCH_SWITCH',
    name: 'Clutch Switch',
    iconComponent: <FontAwesomeIcon icon={faToggleOn} />,
  },
  CRUISE_CONTROL_STATES: {
    id: 'CRUISE_CONTROL_STATES',
    name: 'Cruise Control States',
    iconComponent: <FontAwesomeIcon icon={faCar} />,
  },
  PTO_STATE: {
    id: 'PTO_STATE',
    name: 'PTO State',
    iconComponent: <FontAwesomeIcon icon={faInfoCircle} />,
  },
  ACCELEROMETER_PEDAL: {
    id: 'ACCELEROMETER_PEDAL',
    name: 'Accelerometer Pedal',
    iconComponent: <FontAwesomeIcon icon={faShippingFast} />,
  },
  FUEL_LEVEL: {
    id: 'FUEL_LEVEL',
    name: 'Fuel Level',
    iconComponent: <LocalGasStationIcon />,
  },
  ENGINE_SPEED: {
    id: 'ENGINE_SPEED',
    name: 'Engine Speed',
    iconComponent: <FontAwesomeIcon icon={faTachometerAlt} />,
  },
  TOTAL_ENGINE_HOURS: {
    id: 'TOTAL_ENGINE_HOURS',
    name: 'Total Engine Hours',
    iconComponent: <FontAwesomeIcon icon={faClock} />,
  },
  HIGH_RES_VEHICLE_DISTANCE: {
    id: 'HIGH_RES_VEHICLE_DISTANCE',
    name: 'High Res Vehicle Distance',
    iconComponent: <FontAwesomeIcon icon={faRoad} />,
  },
  TACHOGRAPH_VEHICLE_SPEED: {
    id: 'TACHOGRAPH_VEHICLE_SPEED',
    name: 'Tachograph Vehicle Speed',
    iconComponent: <FontAwesomeIcon icon={faTachometerAlt} />,
  },
  ENGINE_COOLANT_TEMPERATURE: {
    id: 'ENGINE_COOLANT_TEMPERATURE',
    name: 'Engine Coolant Temperature',
    iconComponent: <FontAwesomeIcon icon={faTemperatureLow} />,
  },
  TOTAL_VEHICLE_DISTANCE: {
    id: 'TOTAL_VEHICLE_DISTANCE',
    name: 'Total Vehicle Distance',
    iconComponent: <FontAwesomeIcon icon={faRoad} />,
  },
  TIME_SINCE_ENGINE_START: {
    id: 'TIME_SINCE_ENGINE_START',
    name: 'Time Since Engine Start',
    iconComponent: <FontAwesomeIcon icon={faHistory} />,
  },
  ENGINE_OPERATION_TIME_SINCE_REBUILD: {
    id: 'ENGINE_OPERATION_TIME_SINCE_REBUILD',
    name: 'Engine Operation Time Since Rebuild',
    iconComponent: <FontAwesomeIcon icon={faHistory} />,
  },
  DRIVER_SAFETY: {
    id: 'DRIVER_SAFETY',
    name: 'Driver Safety',
    iconComponent: <FontAwesomeIcon icon={faHardHat} />,
  },
  CONNECT: {
    id: 'CONNECT',
    name: 'Connect',
    iconComponent: <FontAwesomeIcon icon={faPlug} />,
  },
  MOVING: {
    id: 'MOVING',
    name: 'Moving',
    iconComponent: <FontAwesomeIcon icon={faTruckMoving} />,
  },
  LOCATION_UPDATE: {
    id: 'LOCATION_UPDATE',
    name: 'Location Update',
    iconComponent: <FontAwesomeIcon icon={faMapMarkedAlt} />,
  },
  SCORE: {
    id: 'SCORE',
    name: 'Score',
    iconComponent: <FontAwesomeIcon icon={faStar} />,
  },
  UNLOCK: {
    id: 'UNLOCK',
    name: 'Unlock',
    iconComponent: <FontAwesomeIcon icon={faUnlock} />,
  },
};
// TODO continue ALG, Infinit-I

export const categoryList = [];
Object.entries(categories).forEach(([key, value]) => categoryList.push(value));

export const folderList = [
  {id: 121, name: 'All', alias: 'all', icon: 'listAlt'},
  {id: 124, name: 'Starred', alias: 'starred', icon: 'starBorder'},
];

export const locationConf = {
  speed: {
    id: 'speed',
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    title: 'Speed',
  },
  // units: {
  //   id: 'units',
  //   icon: <FontAwesomeIcon icon={faInfoCircle} />,
  //   title: 'Units',
  // },
  lat: {
    id: 'lat',
    icon: (
      <Avatar
        variant='square'
        style={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          fontSize: 10,
          height: 12,
          width: 22,
          fontWeight: 'bold',
        }}>
        LAT
      </Avatar>
    ),
    title: 'Latitude',
  },
  lng: {
    id: 'lng',
    icon: (
      <Avatar
        variant='square'
        style={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          fontSize: 10,
          height: 12,
          width: 22,
          fontWeight: 'bold',
        }}>
        LNG
      </Avatar>
    ),
    title: 'Longitude',
  },
  heading: {
    id: 'heading',
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    title: 'Heading',
  },
  address: {
    id: 'address',
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    title: 'Address',
  },
};

export const eventPayloadConf = {
  [dataSourceKeys.ASSET_LINK_GLOBAL.id]: {
    dateReported: {
      icon: <EventIcon />,
      title: 'Date Reported',
    },
    dateReceived: {
      icon: <EventIcon />,
      title: 'Date Received',
    },
    battery: {
      icon: <BatteryChargingFullIcon />,
      title: 'Battery Level',
    },
    unitTemperature: {
      icon: (
        <FontAwesomeIcon
          icon={faTemperatureLow}
          style={{paddingLeft: 5, fontSize: 16}}
        />
      ),
      title: 'Unit Internal Temperature (C)',
    },
    msgType: {
      icon: <MessageIcon />,
      title: 'Message Type',
    },
    numSats: {
      icon: <SatelliteIcon />,
      title: 'Number of Satellites',
    },
    timeSinceLastDate: {
      icon: <DateRangeIcon />,
      title: 'Time Since Last Date',
    },
  },
  [dataSourceKeys.SAFE_DRIVE_POD.id]: {
    telephone: {
      icon: <FontAwesomeIcon icon={faPhone} style={{fontSize: 16}} />,
      title: 'Telephone',
    },
    phoneLocked: {
      icon: <FontAwesomeIcon icon={faMobile} style={{fontSize: 16}} />,
      title: 'Phone Locked',
    },
    appVersion: {
      icon: <MessageIcon />,
      title: 'App Version',
    },
    mac: {
      icon: <FontAwesomeIcon icon={faAddressCard} />,
      title: 'Mac',
    },
    osVersion: {
      icon: <InfoIcon />,
      title: 'OS Version',
    },
    locationStatus: {
      icon: <FontAwesomeIcon icon={faThumbtack} />,
      title: 'Location Status',
    },
    bleStatus: {
      icon: <InfoIcon />,
      title: 'BLE Status',
    },
    battery: {
      icon: <FontAwesomeIcon icon={faBatteryFull} />,
      title: 'Battery',
    },
    phoneType: {
      icon: <FontAwesomeIcon icon={faMobile} />,
      title: 'Phone Type',
    },
  },
  [dataSourceKeys.LIFESAVER.id]: {
    callDuration: {
      icon: <FontAwesomeIcon icon={faClock} />,
      title: 'Call Duration',
    },
    unlockId: {
      icon: <FontAwesomeIcon icon={faIdCard} />,
      title: 'Unlock ID',
    },
    year: {
      icon: <FontAwesomeIcon icon={faCalendarDay} />,
      title: 'Year',
    },
    month: {
      icon: <FontAwesomeIcon icon={faCalendarDay} />,
      title: 'Month',
    },
    score: {
      icon: <FontAwesomeIcon icon={faStar} />,
      title: 'Score',
    },
    totalDriveTime: {
      icon: <FontAwesomeIcon icon={faUserClock} />,
      title: 'Total Drive Time',
    },
    totalViolations: {
      icon: <FontAwesomeIcon icon={faThumbsDown} />,
      title: 'Total Violations',
    },
  },
};

export const eventConf = {
  category: {
    id: 'category',
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    title: 'Category',
  },
  datetime: {
    id: 'datetime',
    icon: <EventIcon style={{paddingTop: 3}} />,
    title: 'Date',
  },
  recordedVideo: {
    id: 'recordedVideo',
    icon: <VideocamIcon style={{paddingTop: 3}} />,
    title: 'Recorded Video',
  },
};

export const deviceConf = {
  dataSourceId: {
    id: 'dataSourceId',
    icon: <RouterIcon />,
    title: 'Device ID',
  },
  esn: {
    id: 'esn',
    icon: (
      <Avatar
        variant='square'
        style={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          fontSize: 10,
          height: 12,
          width: 22,
          fontWeight: 'bold',
        }}>
        ESN
      </Avatar>
    ),
    title: 'ESN',
  },
};

export const vehicleConf = {
  dataSourceId: {
    id: 'dataSourceId',
    icon: <InfoIcon />,
    title: 'Vehicle ID',
  },
  vin: {
    id: 'vin',
    icon: (
      <Avatar
        variant='square'
        style={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          fontSize: 10,
          height: 12,
          width: 22,
          fontWeight: 'bold',
        }}>
        VIN
      </Avatar>
    ),
    title: 'VIN',
  },
  make: {
    id: 'make',
    icon: <InfoIcon />,
    title: 'Make',
  },
  model: {
    id: 'model',
    icon: <InfoIcon />,
    title: 'Model',
  },
  year: {
    id: 'year',
    icon: <InfoIcon />,
    title: 'Year',
  },
};

export const driverConf = {
  name: {
    id: 'name',
    icon: <PersonIcon />,
    title: 'Name',
  },
};

export const aggregationPeriods = {
  MINUTELY: {
    id: 'MINUTELY',
    name: 'Minutely',
  },
  HOURLY: {
    id: 'HOURLY',
    name: 'Hourly',
  },
  DAILY: {
    id: 'DAILY',
    name: 'Daily',
  },
  WEEKLY: {
    id: 'WEEKLY',
    name: 'Weekly',
  },
  MONTHLY: {
    id: 'MONTHLY',
    name: 'Monthly',
  },
};

export const timeIntervals = {
  '1hour': {
    id: '1hour',
    name: '1 hour',
    availablePeriods: [aggregationPeriods.MINUTELY.id],
    timeFormat: 't1',
  },
  '24hours': {
    id: '24hours',
    name: '24 hours',
    availablePeriods: [aggregationPeriods.HOURLY.id],
    timeFormat: 't1',
  },
  '7days': {
    id: '7days',
    name: '7 days',
    availablePeriods: [
      aggregationPeriods.HOURLY.id,
      aggregationPeriods.DAILY.id,
    ],
    timeFormat: 't2',
  },
  '30days': {
    id: '30days',
    name: '30 days',
    availablePeriods: [
      aggregationPeriods.DAILY.id,
      aggregationPeriods.WEEKLY.id,
    ],
    timeFormat: 't2',
  },
  '3months': {
    id: '3months',
    name: '3 month',
    availablePeriods: [aggregationPeriods.WEEKLY.id],
    timeFormat: 't2',
  },
  customRange: {
    id: 'customRange',
    name: 'Custom range',
    availablePeriods: [
      aggregationPeriods.DAILY.id,
      aggregationPeriods.WEEKLY.id,
      aggregationPeriods.MONTHLY.id,
    ],
    timeFormat: 't2',
  },
};

export const chartTypesDict = {
  StackedAreaChart: {
    id: 'StackedAreaChart',
    name: 'Stacked Area Chart',
  },
  AreaChart: {
    id: 'AreaChart',
    name: 'Area Chart',
  },
  LineChart: {
    id: 'LineChart',
    name: 'Line Chart',
  },
  StackedBarChart: {
    id: 'StackedBarChart',
    name: 'Stacked Bar Chart',
  },
  TinyBarChart: {
    id: 'TinyBarChart',
    name: 'Tiny Bar Chart',
  },
  PieChart: {
    id: 'PieChart',
    name: 'Pie Chart',
  },
  DoughnutChart: {
    id: 'DoughnutChart',
    name: 'Doughnut Chart',
  },
  Treemap: {
    id: 'Treemap',
    name: 'Treemap Chart',
  },
};
