import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const dashboardConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: ['/dashboard/:tab/'],
        component: React.lazy(() => import('./index')),
      },
      {
        path: '/dashboard/',
        component: React.lazy(() => import('./index')),
      },
    ],
  },
];

export const widgetTypeDict = {
  CUSTOM_WIDGET: {
    id: 'CUSTOM_WIDGET',
    name: 'Custom Widget',
  },
  PERCENTAGE_WIDGET: {
    id: 'PERCENTAGE_WIDGET',
    name: 'Percentage Widget',
  },
  NUMBER_WIDGET: {
    id: 'NUMBER_WIDGET',
    name: 'Number Widget',
  },
  SPEEDING_VIOLATIONS: {
    id: 'SPEEDING_VIOLATIONS',
    name: 'Speeding Violations',
  },
};
