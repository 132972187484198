import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Auth0Provider} from '@auth0/auth0-react';
import WebSocketConnection from './core/WebSocketConnection';
import Settings from './core/Settings';
import CookieConsent from 'react-cookie-consent';
import {SnackbarProvider} from 'notistack';

const store = configureStore();

if (
  process.env.REACT_APP_SENTRY_DNS &&
  process.env.REACT_APP_NODE_ENV !== 'development'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    // logLevel: Sentry.Severity.Warning,
    environment: new URL(process.env.REACT_APP_HOST).host,
  });
}

const App = () => (
  <ContextProvider>
    <Provider store={store}>
      <CremaThemeProvider>
        <CremaStyleProvider>
          <LocaleProvider>
            <ConnectedRouter history={history}>
              <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                redirectUri={window.location.origin}
                audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                useRefreshTokens={true}
                cacheLocation={'localstorage'}>
                <AuthRoutes>
                  <SnackbarProvider maxSnack={7}>
                    <Settings>
                      <WebSocketConnection>
                        <CssBaseline />
                        <AppLayout />
                        <CookieConsent
                          location='bottom'
                          buttonText='ACCEPT COOKIES'
                          cookieName='Cookie'
                          style={{
                            backgroundColor: '#000000',
                            color: '#FFFFFF',
                            padding: '30px 100px 30px 100px',
                            opacity: '0.6',
                          }}
                          buttonStyle={{
                            color: '#000000',
                            fontSize: '13px',
                            padding: '15px 20px 15px 20px',
                            backgroundColor: '#ffffff',
                            border: '1px solid #000000',
                          }}
                          expires={150}>
                          This website uses cookies to enhance user experience
                          and to analyse performance and traffic on our website.
                          We also share information about your use of our site
                          with our social media, advertising and analytics
                          partners.{' '}
                          <a
                            style={{color: '#FFFFFF'}}
                            target='_blank'
                            href='https://roadz.com/privacy-policy/'
                            rel='noopener noreferrer'>
                            Please see our Privacy Policy.
                          </a>
                        </CookieConsent>
                      </WebSocketConnection>
                    </Settings>
                  </SnackbarProvider>
                </AuthRoutes>
              </Auth0Provider>
            </ConnectedRouter>
          </LocaleProvider>
        </CremaStyleProvider>
      </CremaThemeProvider>
    </Provider>
  </ContextProvider>
);

export default App;
