import {
  GET_DATA_LOG_LIST,
  UPDATE_DATA_LOG_EXTENSION,
  SELECT_DATA_LOG_LOCATION,
  UPDATE_DATA_LOG_COUNT,
  ADD_DATA_LOG,
  DELETE_DATA_LOGS,
  GET_DATA_SOURCE_CATEGORIES,
} from '../../shared/constants/ActionTypes';
import camelcaseKeys from 'camelcase-keys';

const initialState = {
  dataLogList: null,
  totalDataLogs: 0,
  selectedDataLogLocation: null,
  sourceCategories: null,
};

const dataLogReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_DATA_LOG_LIST:
      return {
        ...state,
        dataLogList: payload,
        // totalDataLogs: payload.length,
      };

    case SELECT_DATA_LOG_LOCATION:
      return {
        ...state,
        selectedDataLogLocation: payload,
      };

    case UPDATE_DATA_LOG_EXTENSION:
      return {
        ...state,
        dataLogList: state.dataLogList
          ? state.dataLogList.map((dataLog) =>
              dataLog.uid === payload.uid
                ? {
                    ...dataLog,
                    extensionData: {
                      isStarred: payload.isStarred,
                      note: payload.note,
                    },
                  }
                : dataLog,
            )
          : null,
      };

    case UPDATE_DATA_LOG_COUNT:
      return {
        ...state,
        totalDataLogs: payload,
      };

    case ADD_DATA_LOG:
      if (
        state.dataLogList &&
        state.dataLogList.filter(
          (item) =>
            item.id === payload.id &&
            item.dataSourceKey === payload.dataSourceKey,
        ).length === 0
      ) {
        const newDataLogList = [payload, ...state.dataLogList];
        if (newDataLogList.length > 800) {
          newDataLogList.pop();
        }
        return {
          ...state,
          dataLogList: newDataLogList,
        };
      } else if (!state.dataLogList) {
        return {
          ...state,
          dataLogList: [payload],
        };
      } else {
        return {
          ...state,
          dataLogList: [payload],
        };
      }

    case DELETE_DATA_LOGS:
      return {
        ...state,
        dataLogList: state.dataLogList
          ? state.dataLogList.filter(
              (dataLog) => dataLog.dataSourceKey !== payload,
            )
          : null,
        totalDataLogs: state.dataLogList.length,
      };

    case GET_DATA_SOURCE_CATEGORIES:
      return {
        ...state,
        sourceCategories: action.payload,
      };

    default:
      return state;
  }
};
export default dataLogReducer;
