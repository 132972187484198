import camelcaseKeys from 'camelcase-keys';

import {GET_PREDEFINED_WIDGET_LIST} from '../../shared/constants/ActionTypes';

const initialState = {
  predefinedWidgetList: null,
};

const predefinedWidgetReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_PREDEFINED_WIDGET_LIST:
      return {
        ...state,
        predefinedWidgetList: payload,
      };

    default:
      return state;
  }
};

export default predefinedWidgetReducer;
