import {
  SET_INITIAL_PATH,
  TOGGLE_NAV_COLLAPSED,
  FETCH_SETTINGS,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/Utils';

export const toggleNavCollapsed = () => {
  return (dispatch) => dispatch({type: TOGGLE_NAV_COLLAPSED});
};

export const setInitialPath = (initialPath) => {
  return (dispatch) => dispatch({type: SET_INITIAL_PATH, payload: initialPath});
};

export const onFetchMainSettings = () => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get(`/api/main/settings/`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_SETTINGS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
