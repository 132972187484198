import {SAVE_CURRENT_ROUTE} from '../../shared/constants/ActionTypes';

const initialState = {
  currentRoute: {
    timeInterval: '24hours',
    categories: [],
    driverGroups: [], // entityDriverGroups
    deviceGroups: [], // entityDeviceGroups
    vehicleGroups: [], // entityVehicleGroups
    entityTags: [],
    drivers: [], // unifiedDrivers
    devices: [], // unifiedDevices
    vehicles: [], // unifiedVehicles
    trips: [], // unifiedTrips
    dataSourceKeys: [],
    datetimeStart: null,
    datetimeEnd: null,
    starred: false,
  },
};

const routeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: {...state.currentRoute, ...action.payload},
      };

    default:
      return state;
  }
};
export default routeReducer;
