import {UPDATE_AUTH_USER} from '../../shared/constants/ActionTypes';
import {defaultUser} from '../../shared/constants/AppConst';

export const onSignInAuth0User = (user) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: {
        role: defaultUser.role,
        displayName: user.name,
        email: user.email,
        photoURL: user.picture || defaultUser.photoURL,
      },
    });
  };
};

export const onSignOutAuth0User = () => {
  return async (dispatch) => {
    dispatch({type: UPDATE_AUTH_USER, payload: null});
  };
};
