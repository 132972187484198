import {
  GET_USER_PROFILE_LIST,
  UPDATE_USER_PROFILE,
} from '../../shared/constants/ActionTypes';
import camelcaseKeys from 'camelcase-keys';

const initialState = {
  userProfile: {
    disabledCategories: {},
    collapsedFilters: ['sources', 'categories', 'drivers', 'trips'],
  },
};

const userProfileReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload);

  switch (action.type) {
    case GET_USER_PROFILE_LIST:
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: {...state.userProfile, ...payload},
      };

    default:
      return state;
  }
};
export default userProfileReducer;
