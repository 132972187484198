import camelcaseKeys from 'camelcase-keys';

import {
  CREATE_NEW_ENTITY_DRIVER,
  DELETE_ENTITY_DRIVER,
  GET_ENTITY_DRIVER_LIST,
  GET_ENTITY_DRIVER_LIST_ALL,
  ON_REMOVE_ENTITY_TAG,
  UPDATE_ENTITY_DRIVER,
} from '../../shared/constants/ActionTypes';

const initialState = {
  entityDriverListAll: null,
  entityDriverList: null,
  entityDriverCount: 0,
};

const entityDriverReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_ENTITY_DRIVER_LIST_ALL:
      return {
        ...state,
        entityDriverListAll: payload,
      };

    case GET_ENTITY_DRIVER_LIST:
      return {
        ...state,
        entityDriverList: payload.results,
        entityDriverCount: payload.count,
      };

    case CREATE_NEW_ENTITY_DRIVER:
      return {
        ...state,
        entityDriverList: [action.payload, ...state.entityDriverList],
        entityDriverCount: state.entityDriverCount + 1,
      };

    case DELETE_ENTITY_DRIVER: {
      return {
        ...state,
        entityDriverList: state.entityDriverList
          ? state.entityDriverList.filter((item) => item.id !== action.payload)
          : null,
        entityDriverCount: state.entityDriverCount - 1,
      };
    }

    case UPDATE_ENTITY_DRIVER:
      return {
        ...state,
        entityDriverList: state.entityDriverList.map((entity) =>
          entity.id === payload.id ? payload : entity,
        ),
      };

    case ON_REMOVE_ENTITY_TAG:
      return {
        ...state,
        entityDriverList:
          state.entityDriverList &&
          state.entityDriverList.map((entity) =>
            entity.id === payload.entityGroupId
              ? {
                  ...entity,
                  tags:
                    entity.tags &&
                    entity.tags.filter((tag) => tag.id !== payload.id),
                }
              : entity,
          ),
      };

    default:
      return state;
  }
};
export default entityDriverReducer;
