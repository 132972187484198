import {
  ADD_NOTIFICATION,
  DELETE_READ_NOTIFICATION,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_NOTIFICATION_LIST,
  GET_UNREAD_NOTIFICATION_LIST,
  NOTIFICATION_READ_ALL,
  ON_NOTIFICATION_PAGE_CHANGE,
  READ_NOTIFICATION,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/Utils';

export const onGetUnreadNotificationList = (unread = null) => {
  const {messages} = appIntl();
  const params = {};

  if (unread != null) {
    params.read = unread;
  }
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get(`/api/notification/notifications/`, {
      params,
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_UNREAD_NOTIFICATION_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetNotificationList = (
  unread = null,
  search = null,
  currentPage = 0,
) => {
  const {messages} = appIntl();
  const params = {};

  if (unread != null) {
    params.read = unread;
  }
  if (currentPage) {
    params.page = currentPage + 1;
  }
  if (search) {
    params.name__contains = search;
  }
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get(`/api/notification/notifications/`, {
      params,
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_NOTIFICATION_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onNotificationChange = (page) => {
  return (dispatch) => {
    dispatch({type: ON_NOTIFICATION_PAGE_CHANGE, payload: page});
  };
};

export const onReadNotification = (notificationId, data) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.patch(`/api/notification/notifications/${notificationId}/`, data)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: READ_NOTIFICATION, payload: data.data});

          dispatch({type: DELETE_READ_NOTIFICATION, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddNotificationLocal = (notification) => {
  return (dispatch) => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification,
    });
  };
};

export const onReadAllNotification = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post(`/api/notification/notifications/read_all/`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: READ_NOTIFICATION, payload: data.data});

          dispatch({type: NOTIFICATION_READ_ALL, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
