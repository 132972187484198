import camelcaseKeys from 'camelcase-keys';

import {
  GET_ENTITY_TAG_LIST,
  ON_CREATE_ENTITY_TAG,
  ON_DELETE_ENTITY_TAG,
  ON_EDIT_ENTITY_TAG,
} from '../../shared/constants/ActionTypes';

const initialState = {
  entityTagList: null,
};

const entityTagReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_ENTITY_TAG_LIST:
      return {
        ...state,
        entityTagList: payload,
      };

    case ON_CREATE_ENTITY_TAG:
      return {
        ...state,
        entityTagList: [...state.entityTagList, payload],
      };

    case ON_EDIT_ENTITY_TAG:
      return {
        ...state,
        entityTagList: state.entityTagList.map((tag) =>
          tag.id === payload.id ? payload : tag,
        ),
      };

    case ON_DELETE_ENTITY_TAG: {
      return {
        ...state,
        entityTagList: state.entityTagList
          ? state.entityTagList.filter((item) => item.id !== action.payload.id)
          : null,
      };
    }

    default:
      return state;
  }
};
export default entityTagReducer;
