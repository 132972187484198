import camelcaseKeys from 'camelcase-keys';

import {GET_CHART_DATASET} from '../../shared/constants/ActionTypes';

const initialState = {
  chartDataset: null,
};

const chartDatasetReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_CHART_DATASET:
      return {
        ...state,
        chartDataset: payload,
      };

    default:
      return state;
  }
};
export default chartDatasetReducer;
