import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {checkPermission} from './Utils';
import {initialUrl} from '../../shared/constants/AppConst';
import {useAuth0} from '@auth0/auth0-react';
import axios from 'axios';
import {onSignInAuth0User} from '../../redux/actions/Auth0';

const AuthRoutes = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    updateThemeMode,
    setRTL,
  } = useContext(AppContext);
  const {
    loginWithRedirect,
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const currentRoute = matchRoutes(routes, pathname)[0].route;
  const reduxUser = useSelector(({auth}) => auth).user;
  let isPermitted = checkPermission(
    currentRoute.auth,
    reduxUser ? reduxUser.role : null,
  );

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout);
      }
      if (query.mode) {
        updateThemeMode(query.mode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle(query.style);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      dispatch(onSignInAuth0User(user));
      try {
        getAccessTokenSilently()
          .then((accessToken) => {
            axios.interceptors.request.use((config) => {
              if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
              }
              return config;
            });
            axios.interceptors.response.use(
              function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                return response;
              },
              function (error) {
                if (error.response && error.response.status === 404) {
                  history.push('/error-pages/error-404'); // Not found
                } else if (error.response && error.response.status === 401) {
                  logout({
                    returnTo: process.env.REACT_APP_HOST,
                  });
                  loginWithRedirect();
                }

                return Promise.reject(error);
              },
            );
          })
          .catch((err) => {});
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }, [
    dispatch,
    isLoading,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    history,
    logout,
    loginWithRedirect,
  ]);

  useEffect(() => {
    (async function () {
      if (!isLoading) {
        if (!user) {
          const allowedCallbacks = [process.env.REACT_APP_HOST];
          const options = {};
          if (allowedCallbacks.includes(window.location.href)) {
            options.redirectUri = window.location.href;
          }
          await loginWithRedirect(options);
        } else if (reduxUser && !isPermitted) {
          history.push('/error-pages/error-404'); // Not found
        } else if (reduxUser && isPermitted) {
          if (pathname === '/') {
            history.push(initialUrl);
          }
        }
      }
    })();
  }, [
    user,
    isPermitted,
    isLoading,
    pathname,
    history,
    loginWithRedirect,
    reduxUser,
  ]);

  return isLoading || !isAuthenticated ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
