import camelcaseKeys from 'camelcase-keys';

import {
  CREATE_NEW_DASHBOARD,
  DELETE_DASHBOARD,
  GET_DASHBOARD_LIST,
  UPDATE_DASHBOARD,
} from '../../shared/constants/ActionTypes';

const initialState = {
  dashboardList: null,
};

const dashboardReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_DASHBOARD_LIST:
      return {
        ...state,
        dashboardList: payload,
      };

    case CREATE_NEW_DASHBOARD:
      return {
        ...state,
        dashboardList: [...state.dashboardList, action.payload],
      };

    case DELETE_DASHBOARD: {
      return {
        ...state,
        dashboardList: state.dashboardList
          ? state.dashboardList.filter((item) => item.id !== action.payload)
          : null,
      };
    }

    case UPDATE_DASHBOARD:
      return {
        ...state,
        dashboardList: state.dashboardList.map((dashboard) =>
          dashboard.id === payload.id ? payload : dashboard,
        ),
      };

    default:
      return state;
  }
};

export default dashboardReducer;
