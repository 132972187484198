import React, {useEffect} from 'react';
import {Loader} from '../../../@crema';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {hideMessage} from '../../../redux/actions';

const InfoView = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {error, loading, message, warning} = useSelector(({common}) => common);

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, {variant: 'success'});
    }
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
    if (warning) {
      enqueueSnackbar(warning, {variant: 'warning'});
    }
    if (message || error) {
      dispatch(hideMessage());
    }
  }, [dispatch, enqueueSnackbar, message, error, warning]);

  return <>{loading && <Loader />}</>;
};

export default InfoView;
