import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {ListItem, makeStyles} from '@material-ui/core';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {onReadNotification} from '../../../redux/actions/Notification';
import {useDispatch} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 20px',
  },
}));

const NotificationItem = (props) => {
  const {item} = props;
  const classes = useStyles(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(null);

  return (
    <ListItem
      button={!!item.url}
      style={{display: 'flex'}}
      className={clsx(classes.listItemRoot, 'item-hover')}
      onClick={() => {
        if (item.url) {
          history.push(item.url);
        }
      }}>
      <Box component='p' className={classes.textBase} color='text.secondary'>
        <Box
          mr={2}
          component='span'
          display='inline-block'
          color='text.primary'
          fontWeight={Fonts.MEDIUM}>
          {item.name}
        </Box>
        {item.message}
      </Box>

      <Box ml='auto' onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={item.id === checked}
          onChange={(e) => {
            setChecked(item.id);
            dispatch(onReadNotification(item.id, {read: true}));
          }}
          value='checkedB'
          color='primary'
          inputProps={{
            'aria-label': 'secondary checkbox',
          }}
        />
      </Box>
    </ListItem>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
