import React, {useContext, useMemo} from 'react';
import {Icon, ListItem, ListItemText} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Badge, NavLink} from '../../../index';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalItem.style';
import AppContext from '../../../utility/AppContext';
import {checkPermission} from '../../../utility/Utils';
import {useSelector} from 'react-redux';

const VerticalItem = ({level, item}) => {
  const {themeMode} = useContext(AppContext);
  const classes = useStyles({level, themeMode});
  const {user} = useSelector(({auth}) => auth);
  const notificationUnreadCount = useSelector(
    ({notification}) => notification,
  ).notificationUnreadCount;
  const hasPermission = useMemo(
    () => checkPermission(item.auth, user.role),
    [item.auth, user.role],
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <Box position='relative'>
      {item.notificationCount && notificationUnreadCount ? (
        <Box className={classes.badge}>
          <Badge count={notificationUnreadCount} color={item.color} />
        </Box>
      ) : null}

      <ListItem
        button
        component={NavLink}
        to={item.url}
        activeClassName='active'
        className={classes.navItem}
        exact={item.exact}>
        {item.icon && (
          <Box>
            <Icon
              className={clsx(classes.listIcon, 'nav-item-icon')}
              color='action'>
              {item.icon}
            </Icon>
          </Box>
        )}

        <Box mt={1} mb={3}>
          <ListItemText
            style={{whiteSpace: 'nowrap'}}
            primary={<IntlMessages id={item.messageId} />}
            classes={{primary: 'nav-item-text'}}
          />
        </Box>
      </ListItem>
    </Box>
  );
};

VerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

VerticalItem.defaultProps = {};

export default withRouter(React.memo(VerticalItem));
