import camelcaseKeys from 'camelcase-keys';

import {
  CREATE_NEW_ENTITY_VEHICLE,
  DELETE_ENTITY_VEHICLE,
  GET_ENTITY_VEHICLE_LIST,
  GET_ENTITY_VEHICLE_LIST_ALL,
  ON_REMOVE_ENTITY_TAG,
  UPDATE_ENTITY_VEHICLE,
} from '../../shared/constants/ActionTypes';

const initialState = {
  entityVehicleListAll: null,
  entityVehicleList: null,
  entityVehicleCount: 0,
};

const entityVehicleReducer = (state = initialState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case GET_ENTITY_VEHICLE_LIST_ALL:
      return {
        ...state,
        entityVehicleListAll: payload,
      };

    case GET_ENTITY_VEHICLE_LIST:
      return {
        ...state,
        entityVehicleList: payload.results,
        entityVehicleCount: payload.count,
      };

    case CREATE_NEW_ENTITY_VEHICLE:
      return {
        ...state,
        entityVehicleList: [action.payload, ...state.entityVehicleList],
        entityVehicleCount: state.entityVehicleCount + 1,
      };

    case DELETE_ENTITY_VEHICLE: {
      return {
        ...state,
        entityVehicleList: state.entityVehicleList
          ? state.entityVehicleList.filter((item) => item.id !== action.payload)
          : null,
        entityVehicleCount: state.entityVehicleCount - 1,
      };
    }

    case UPDATE_ENTITY_VEHICLE:
      return {
        ...state,
        entityVehicleList: state.entityVehicleList.map((entity) =>
          entity.id === payload.id ? payload : entity,
        ),
      };

    case ON_REMOVE_ENTITY_TAG:
      return {
        ...state,
        entityVehicleList:
          state.entityVehicleList &&
          state.entityVehicleList.map((entity) =>
            entity.id === payload.entityGroupId
              ? {
                  ...entity,
                  tags:
                    entity.tags &&
                    entity.tags.filter((tag) => tag.id !== payload.id),
                }
              : entity,
          ),
      };

    default:
      return state;
  }
};
export default entityVehicleReducer;
